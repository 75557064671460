import { useAuthStore } from '@stores/authStore';
import { useCourseStore } from '@stores/courseStore';
import { useDirectionStore } from '@stores/directionStore';
import { useEnumsStore } from '@stores/enumsStore';

export const clearAllStores = () => {
    const authStore = useAuthStore();
    const courseStore = useCourseStore();
    const directionStore = useDirectionStore();
    const enumsStore = useEnumsStore();

    authStore.clearToken();
    courseStore.clearAll();
    directionStore.clearAll();
    enumsStore.clearAll();
};
