import { ref } from "vue";

export default function useTheme() {
    const theme = ref(localStorage.getItem('theme') || 'light');

    const toggleTheme = () => {
        theme.value = theme.value === 'light' ? 'dark' : 'light';
        localStorage.setItem('theme', theme.value);
        applyTheme();
    };
    const applyTheme = () => {
        localStorage.setItem('theme', theme.value);
        document.documentElement.className = theme.value;
    };

    return {
        theme,
        toggleTheme,
        applyTheme,
    };
}
