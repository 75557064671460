import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPersistedState from 'pinia-plugin-persistedstate'
import '@/assets/scss/style.css'

// Импортируйте корневой компонент приложения
import App from './App.vue'

// Импортируйте и настройте маршрутизатор
import router from '@/router'

import LoginLayout from "@/layouts/LoginLayout.vue";
import MainLayout from "@/layouts/MainLayout.vue";

// Создайте экземпляр приложения
const app = createApp(App)
const pinia = createPinia()
pinia.use(piniaPersistedState)

// Используйте маршрутизатор и pinia
app.use(router)
app.use(pinia)

app.component('LoginLayout', LoginLayout)
app.component('MainLayout', MainLayout)
app.mount('#app')
