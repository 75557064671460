import { defineStore } from "pinia";
import { ref } from "vue";
import useAxios from '@composables/useAxios';


export const useDirectionStore = defineStore('direction', () => {
    const { get } = useAxios();
    const isLoadingDirections = ref(false);
    const directions = ref([]);

    const directionsClear = () => {
        directions.value = [];
    }

    const filteredDirections = ( groupKey ) => {
        return directions.value.filter(direction => direction.group_key === groupKey);
    };

    const fetchDirections = ( url = '/direction/list' ) => {
        isLoadingDirections.value = true
        get(url)
            .then(( { data } ) => {
                directions.value = data
            })
            .catch(err => {
                console.error('Error fetching data:', err);
            })
            .finally(() => {
                isLoadingDirections.value = false
            });

    }

    const isLoadingDirectionsCourses = ref(false);
    const directionCourses = ref({});

    const fetchDirectionCourses = ( directionKey ) => {
        isLoadingDirectionsCourses.value = true
        get(`/direction/courses/${directionKey}`)
            .then(( data ) => {
                directionCourses.value = data
            })
            .catch(err => {
                console.error('Error fetching data:', err);
            })
            .finally(() => {
                isLoadingDirectionsCourses.value = false
            });
    };

    const clearAll = () => {
        isLoadingDirections.value = false;
        directions.value = [];
        isLoadingDirectionsCourses.value = false;
        directionCourses.value = {};
    }

    return {
        directions,
        isLoadingDirections,
        filteredDirections,
        directionsClear,
        fetchDirections,
        fetchDirectionCourses,
        directionCourses,
        isLoadingDirectionsCourses,
        clearAll,
    };
}, {
    persist: true
});
