import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export const useAuthStore = defineStore('auth', () => {
    const token = ref(localStorage.getItem('authToken') || '');
    const isAuthenticated = ref(!!token.value);
    const router = useRouter();

    const setToken = (newToken) => {
        token.value = newToken;
        isAuthenticated.value = true;
        localStorage.setItem('authToken', newToken);
    };

    const clearToken = () => {
        token.value = '';
        isAuthenticated.value = false;
        localStorage.removeItem('authToken');
        router.push({ name: 'login' });  // Редирект на страницу входа
    };

    const loadStateFromLocalStorage = () => {
        const storedToken = localStorage.getItem('authToken');
        if (storedToken) {
            token.value = storedToken;
            isAuthenticated.value = true;
        } else {
            token.value = '';
            isAuthenticated.value = false;
        }
    };

    return {
        token,
        isAuthenticated,
        setToken,
        clearToken,
        loadStateFromLocalStorage
    };
}, {
    persist: true
});
