import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@stores/authStore'

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/LoginPage.vue'),
        meta: {
            layout: 'LoginLayout',
            authZone: false
        }
    },
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/HomePage.vue'),
        meta: {
            layout: 'MainLayout',
            authZone: true
        }
    },
    {
        path: '/directions',
        name: 'directions',
        component: () => import('@/views/DirectionsPage.vue'),
        meta: {
            layout: 'MainLayout',
            authZone: true
        }
    },
    {
        path: '/directions/:direction_key',
        name: 'directionsSelected',
        component: () => import('@/views/DirectionsSelectedPage.vue'),
        meta: {
            layout: 'MainLayout',
            authZone: true
        },
        props: true
    },
    {
        path: '/directions/:direction_key/courses/:course_key',
        name: 'course',
        component: () => import('@/views/CoursePage.vue'),
        meta: {
            layout: 'MainLayout',
            authZone: true
        },
        props: true
    },
    {
        path: '/directions/:direction_key/courses/:course_key/block/:block_id',
        name: 'courseBlock',
        component: () => import('@/views/CourseBlockPage.vue'),
        meta: {
            layout: 'MainLayout',
            authZone: true
        },
        props: true
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'notfound',
        beforeEnter: ( to, from, next ) => next({ name: 'directions' })
    }
]


const router = createRouter({
    history: createWebHistory(),
    routes,
    // scrollBehavior( to, from, savedPosition ) {
    //     if (to.hash) {
    //         // Если есть якорь в URL, прокрутите к нему
    //         return { el: to.hash, behavior: 'smooth' };
    //     } else {
    //         // В противном случае, прокрутите вверх страницы
    //         return { left: 0, top: 0 };
    //     }
    // }
});

router.beforeEach((to, from, next) => {
    const store = useAuthStore()

    if (to.meta.authZone !== false && !store.isAuthenticated) {
        next({ name: 'login' })
    } else if (to.name === 'home') {
        next({ name: 'directions' })
    } else {
        next()
    }
})

// router.beforeEach(( to, from, next ) => {
//     console.log('Navigating to:', to.fullPath);
//     next();
// });

// router.beforeEach(( to, from, next ) => {
//     // Проверка, не является ли целевой маршрут главной страницей
//     if (to.path !== '/') {
//         if (!auth.check()) {
//             next({ path: '/', query: { login: 'dev' } })
//         } else {
//             next()
//         }
//     } else {
//         next()
//     }
// });

export default router;
