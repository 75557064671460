import { defineStore } from "pinia";
import { computed, ref } from "vue";
import useAxios from '@composables/useAxios';


export const useEnumsStore = defineStore('enums', () => {
    const { get } = useAxios();
    const enums = ref({})
    const isLoadingEnums = ref(false);

    const enumsClear = () => {
        enums.value = {};
    }

    const enumsGroups = computed(() => {
        return Object.keys(enums.value).length ? enums.value.groups : []
    })

    const fetchEnums = ( url = '/enum?v[0]=groups&v[1]=access_type' ) => {
        isLoadingEnums.value = true
        get(url)
            .then(( data ) => {
                enums.value = data
            })
            .catch(err => {
                console.error('Error fetching data:', err);
            })
            .finally(() => {
                isLoadingEnums.value = false
            });
    }

    const clearAll = () => {
        enums.value = {}
        isLoadingEnums.value = false
    }

    return {
        enums,
        isLoadingEnums,
        enumsClear,
        enumsGroups,
        fetchEnums,
        clearAll,
    };
}, {
    persist: true
});
