import { defineStore } from "pinia";
import { ref } from "vue";
import useAxios from '@composables/useAxios';

export const useCourseStore = defineStore('course', () => {
    const { get } = useAxios();
    const isLoadingCourse = ref(false);
    const course = ref({});

    const fetchCourseBlocksByUrl = ( courseKey ) => {
        isLoadingCourse.value = true
        get(`/course/${courseKey}`)
            .then(( data ) => {
                course.value = data.data
            })
            .catch(err => {
                console.error('Error fetching data:', err);
            })
            .finally(() => {
                isLoadingCourse.value = false
            });
    };

    const isLoadingBlockContent = ref(false);
    const content = ref({});
    const contentTheory = ref([]);
    const contentQuestions = ref([]);
    const currentPart = ref(-1);
    const maxParts = ref(-1);

    const fetchCourseBlockContent = ( blockId, partId ) => {
        isLoadingBlockContent.value = true
        get(`/block/${blockId}/part/${partId}`)
            .then(( data ) => {
                if ('error' in data) {
                    // todo-max: доделать!'error' in data /block/${blockId}/part/${partId}
                } else {
                    content.value = data.data
                    currentPart.value = data.currentPart
                    maxParts.value = data.maxParts
                    if ('contents' in data.data) {
                        contentTheory.value = data.data.contents.filter(el => el.type === 'theory');
                        contentQuestions.value = data.data.contents.filter(el => el.type === 'question');
                    }
                }
            })
            .catch(err => {
                console.error('Error fetching data:', err);
            })
            .finally(() => {
                isLoadingBlockContent.value = false
            });
    };

    const isLoadingCheckAnswer = ref(false);
    const checkAnswerResult = ref({}); // isCorrect (bool) / correctId (int)

    const checkAnswer = async ( answerId ) => {
        if (answerId === -1) {
            return false
        }
        isLoadingCheckAnswer.value = true
        await get(`/answer/${answerId}/check`)
            .then(( data ) => {
                checkAnswerResult.value = data
            })
            .catch(err => {
                console.error('Error fetching data:', err);
            })
            .finally(() => {
                isLoadingCheckAnswer.value = false
            });

        return true
    }

    const clearCheckAnswerResult = () => {
        checkAnswerResult.value = {}
    }

    const clearAll = () => {
        isLoadingCourse.value = false;
        course.value = {};
        isLoadingBlockContent.value = false;
        content.value = {};
        contentTheory.value = [];
        contentQuestions.value = [];
        currentPart.value = -1;
        maxParts.value = -1;
        isLoadingCheckAnswer.value = false;
        checkAnswerResult.value = {};
    }
    return {
        course,
        isLoadingCourse,
        fetchCourseBlocksByUrl,
        content,
        isLoadingBlockContent,
        fetchCourseBlockContent,
        contentTheory,
        contentQuestions,
        currentPart,
        maxParts,
        isLoadingCheckAnswer,
        checkAnswerResult,
        checkAnswer,
        clearCheckAnswerResult,
        clearAll,
    }

}, {
    persist: true
});
