<template>
  <div class="main-layout">
    <div class="head-title">
      <div id="t-header-back"></div>
      <a href="/">COMPONENT-A</a>
      <div class="btn" @click="logout">Выход</div>
    </div>

    <main>
      <slot/>
    </main>
  </div>
</template>

<script setup>
import useAxios from '@composables/useAxios';
import { clearAllStores } from '@stores/clearAllStores';

const { post } = useAxios();

const logout = () => {
  post('/user/logout')
      .then(() => {
        clearAllStores()
      })
      .catch(err => {
        console.error('Error fetching data:', err);
      })
};
</script>

<style lang="scss">
.main-layout {

  .head-title {
    @include d-flex(space-between, center, row, '50px');
    text-align: center;
    margin-bottom: 20px;

    a {
      text-decoration: none;
      @include fontMontserratAlternates(50px, var(--text-color), 6);
    }
  }
}
</style>
