import { ref } from 'vue';
import axios from 'axios';
import { useAuthStore } from '@/stores/authStore';

export default function useAxios() {
    const response = ref(null);
    const errorAxios = ref(null);
    const loading = ref(false);
    const authStore = useAuthStore();

    const axiosInstance = axios.create({
        baseURL: import.meta.env.VITE_API_BASE_URL,
        timeout: 10000,
        // headers: {
        //     'X-Custom-Header': 'foobar',
        //     ...(authStore.token && { 'Authorization': `Bearer ${authStore.token}` })
        // }
    });

    axiosInstance.interceptors.response.use(
        response => response,
        error => {
            if (error.response.status === 401) {
                authStore.clearToken();
            }
            return Promise.reject(error);
        }
    );

    const get = ( url, config = {} ) => {
        loading.value = true;
        response.value = null;
        errorAxios.value = null;
        config.headers = {
            ...config.headers,
            ...(authStore.token && { 'Authorization': `Bearer ${authStore.token}` })
        };
        return axiosInstance.get(url, config)
            .then(( res ) => {
                response.value = res.data;
                return res.data;
            })
            .catch(( err ) => {
                errorAxios.value = err;
                throw err;
            })
            .finally(() => {
                loading.value = false;
            });
    };

    const post = ( url, data, config = {} ) => {
        loading.value = true;
        response.value = null;
        errorAxios.value = null;
        config.headers = {
            ...config.headers,
            ...(authStore.token && { 'Authorization': `Bearer ${authStore.token}` })
        };
        return axiosInstance.post(url, data, config)
            .then(( res ) => {
                response.value = res.data;
                return res.data;
            })
            .catch(( err ) => {
                errorAxios.value = err;
                throw err;
            })
            .finally(() => {
                loading.value = false;
            });
    };

    return {
        response,
        errorAxios,
        loading,
        get,
        post
    };
}
