<template>
  <div :class="theme">
    <component :is="layout">
      <router-view :key="$route.fullPath"/>
    </component>
    <div class="btn-toggle-theme" @click="toggleTheme" v-html="btnThemeIco"></div>
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import useTheme from "@composables/useTheme";

const { theme, toggleTheme, applyTheme } = useTheme()

const route = useRoute();
const layout = computed(() => {
  return (route?.meta?.layout);
})

const btnThemeIco = computed(() => {
  return theme.value === 'light' ? '&#9728;' : '&#9788;'
})

onMounted(() => {
  applyTheme();
});
</script>

<style lang="scss">
.btn-toggle-theme {
  position: fixed;
  right: 0;
  bottom: 100px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border: 1px solid grey;
  border-radius: 6px 0 0 6px;
  writing-mode: tb-rl;
  font-weight: bold;
  @include d-flex(center, center)
}
</style>

